import React, { useEffect, useState } from "react";
import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import Testimonial from "./Testimonial";
import { fetchTestimonials } from "../../api/testimonialsApiClient";
import Carousel from "react-material-ui-carousel";
import { useNavigate } from "react-router";

const WordOfMouthCarousel = ({ data }) => {
  return (
    <Carousel sx={{ width: "405px" }} autoPlay={false}>
      {data.map((item, i) => (
        <Testimonial key={i} data={item} />
      ))}
    </Carousel>
  );
};

const WordOfMouth = ({ id, setLoaded, loaded }) => {
  const [testimonialsData, setTestimonialsData] = useState(null);
  const isMinWidthTrue = useMediaQuery("(min-width:890px)");
  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      const res = await fetchTestimonials();
      if (res && !testimonialsData) {
        setTestimonialsData(res.sort((a, b) => a.id - b.id));
      }
    };
    fetch();
  });

  useEffect(() => {
    if (testimonialsData && !loaded.wordOfMouth) {
      setLoaded({ ...loaded, wordOfMouth: true });
    }
  }, [testimonialsData]);

  return (
    <Box
      id={id}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        textAlign: "left",
        marginTop: "80px",
        marginBottom: "80px",
        // backgroundColor: 'white'
      }}
    >
      <Typography
        style={{
          fontSize: "15px",
          width: "93%",
          lineHeight: "16.8px",
          fontWeight: 700,
          textAlign: "left",
          marginBottom: "20px",
          textTransform: "uppercase",
          color: "rgba(255, 255, 255, 0.5)"
        }}
      >
        word of mouth matters
      </Typography>
      <Typography
        style={{
          fontSize: isMinWidthTrue ? "40px" : "32px",
          width: "93%",
          lineHeight: isMinWidthTrue ? "48px" : "40px",
          fontWeight: 700,
          textAlign: "left",
          marginBottom: "50px",
          color: 'white'
        }}
      >
        Hear what our clients have to say
      </Typography>
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        {testimonialsData && isMinWidthTrue ? (
          testimonialsData.map((t, i) => {
            if (i < 3) {
              return <Testimonial key={t.id} data={t} />;
            }
            return null;
          })
        ) : testimonialsData ? (
          <WordOfMouthCarousel data={testimonialsData} />
        ) : null}
      </Box>
      <Button
        variant="outlined"
        style={{
          borderRadius: "50px",
          border: "1px solid #005713",
          textTransform: "capitalize",
          marginTop: "40px",
          color: 'white',
          backgroundColor: 'rgb(38, 128, 56)',
        }}
        onClick={() => navigate("/testimonials")}
      >
        Read More
      </Button>
    </Box>
  );
};

export default WordOfMouth;
