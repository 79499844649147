import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import {
  Hero,
  ContentOverview,
  InvestmentOpportunities,
  ByTheNumbers,
  WordOfMouth
} from "../../components";
import { fetchContentOverviews } from "../../api/contentOverviewApiClient";

const Home = ({ setLoaded, loaded }) => {
  const [contentOverviews, setContentOverviews] = useState();

  useEffect(() => {
    const fetch = async () => {
      const res = await fetchContentOverviews();
      if (!contentOverviews) {
        setContentOverviews(res.sort((a, b) => a.id - b.id));
      }
    };
    fetch();
  }, [contentOverviews]);

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      {loaded.header && <Hero setLoaded={setLoaded} loaded={loaded} />}
      {loaded.hero && (
        <ContentOverview
          data={contentOverviews && contentOverviews[0]}
          setLoaded={setLoaded}
          loaded={loaded}
          overviewId={1}
        />
      )}
      {loaded.contentOverview1 && (
        <InvestmentOpportunities setLoaded={setLoaded} loaded={loaded} />
      )}
      {loaded.investmentOpportunities && (
        <ByTheNumbers setLoaded={setLoaded} loaded={loaded} />
      )}
      {loaded.byTheNumbers && (
        <ContentOverview
          data={contentOverviews && contentOverviews[1]}
          setLoaded={setLoaded}
          loaded={loaded}
          overviewId={2}
        />
      )}
      {loaded.contentOverview2 && (
        <WordOfMouth id="word-of-mouth" setLoaded={setLoaded} loaded={loaded} />
      )}
      {Object.values(loaded).some((el) => !el) && (
        <Box
          display="flex"
          width="100%"
          height="100px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="success" />
        </Box>
      )}
    </Box>
  );
};

export default Home;
